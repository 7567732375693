.button_container {
  height: 40px;
  width: fit-content;
  padding: 0 10px;
  border: none;
  background: none;
  cursor: pointer;
  border-radius: 10px;
  background: transparent;
  font-size: 1rem;
}

.filled {
  background: black;
  color: white;
}

.outlined {
  border: 1px solid black;
  color: black;
}

.input_filed {
  height: 40px;
  width: 100%;
  border-radius: 5px;
  border: none;
  padding: 5px 10px;
  font-size: 1rem;
}

.table_container {
  height: 100%;
  width: 100%;
  border-radius: 10px;
  overflow: auto;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.table {
  width: 100%;
  border-radius: 10px;
  border-collapse: collapse;
  position: relative;
}

.table thead {
  height: fit-content;
  background: lightgrey;
  position: sticky;
  top: 0;
  height: 20%;
}

.table tbody {
  overflow-y: auto;
  height: 100%;
}

.table th {
  padding: 15px 20px;
}

.table td {
  padding: 10px 20px;
}

.table tr:hover td {
  background: rgba(99, 99, 99, 0.1);
  cursor: pointer;
}

.table tr td {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
