.content_main {
	display: flex;
	flex-direction: column;
	height: 100%;
}

.content_container {
	width: 100%;
	display: flex;
	align-items: center;
	margin-bottom: 10px;
}

.content_container h1 {
	font-weight: 600;
	padding: 10px 0;
}

.content_body_container {
	width: 100%;
	flex-grow: 1;
	border-radius: 10px;
	padding: 10px;
	overflow: auto;
}
