.container {
	width: 100vw;
	height: 100vh;
}

.login_nav_container {
	height: fit-content;
	display: flex;
	justify-content: center;
	padding: 40px 0 0 0;
}

.logo {
	z-index: -1;
	background-image: url("");
	background-size: cover;
	background-position: center;
	height: 100px;
	aspect-ratio: 1/1;
}

.modal_container {
	height: calc(100vh);
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.modal {
	height: 400px;
	width: 600px;
	border-radius: 15px;
	position: relative;
	overflow: hidden;
	z-index: 1;
	background: white;
	transform: translateY(0%);
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.modal h1 {
	font-weight: 400;
	padding: 10px 20px;
	background: #000;
	color: #fff;
}

.fields_container {
	display: grid;
	grid-template-columns: 1fr;
	gap: 20px;
	padding-top: 30px;
}

.fields_container > button {
	width: 200px;
	margin: 0 auto;
	height: 45px;
	border-radius: 5px;
	border: none;
	margin-top: 10px;
	background: rgba(0, 0, 0, 0.9);
	color: #fff;
	font-size: 1rem;
	cursor: pointer;
}

.fields_container > button:hover {
	background: rgba(75, 95, 129, 1);
}

.label_and_field {
	width: 100%;
	padding: 0 40px;
}

.label_and_field span {
	display: block;
	margin-bottom: 5px;
}

.label_and_field input {
	height: 45px;
	width: 100%;
	border-radius: 5px;
	border: none;
	background: rgba(173, 216, 230, 0.2);
	padding: 0 10px;
}
