.container {
	position: absolute;
	z-index: 1000;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: rgba(0, 0, 0, 0.2);
	display: flex;
	align-items: center;
	justify-content: center;
}

.folder_card {
	height: 60vh;
	width: 50vw;
	background: white;
	border-radius: 15px;
	overflow: hidden;
}

.folder_header {
	height: 50px;
	width: 100%;
	background: rgba(0, 0, 0, 0.5);
	padding: 0 20px;
	display: flex;
	align-items: center;
	color: white;
}

.folder_header p:last-child {
	cursor: pointer;
}

.image_container {
	padding: 20px;
	height: calc(60vh - 50px - 20px);
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	gap: 10px;
	overflow: auto;
}
