.ads_container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin-top: 20px;
  padding-left: 10px;
  gap: 20px;
}

.ad {
  width: 100%;
  /* border: 1px solid black; */
  /* border-radius: 10px; */
  position: relative;
}

.ad_image {
  width: 100%;
  height: 150px;
  aspect-ratio: 16/9;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 10px 10px 0 0;
}

.ad_title {
  padding: 10px 10px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 0 0 10px 10px;
}

.ad_close {
  position: absolute;
  height: 20px;
  width: 20px;
  border-radius: 100%;
  background: rgb(53, 53, 53);
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  padding: 5px;
}
