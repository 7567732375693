.grid_1 {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 1rem;
}

.grid_2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
}

.textArea {
  width: 100%;
  height: fit-content;
  display: grid;
  grid-template-columns: 1fr;
  gap: 5px;
}

.textArea p,
.logo_container p {
  font-size: 16px;
  font-weight: 500;
  transform: translateX(5px);
}

/* stop text area to increase it width */
.textArea textarea {
  min-height: 200px;
  width: 100%;
  resize: vertical;
  border-radius: 20px;
  padding: 10px;
  font-size: 18px;
  font-family: inherit;
}

.textArea textarea:focus {
  outline-color: grey;
}

.logo_container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 5px;
}

.logo_field {
  width: 100px;
  height: 100px;
  border: 1px dashed grey;
  border-radius: 20px;
  background: lightgrey;
  position: relative;
  cursor: pointer;
}

.logo {
  height: 100px;
  width: fit-content;
  border: 1px dashed grey;
  padding: 10px 15px;
  border-radius: 20px;
  position: relative;
}

.logo_close {
  position: absolute;
  background: black;
  height: 25px;
  width: 25px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 5px;
  right: 5px;
  cursor: pointer;
}

.logo img {
  height: 100%;
  width: auto;
}

.logo_field p {
  position: absolute;
  font-size: 2rem;
  line-height: 2rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -60%);
  color: black;
}

.logo_field input {
  opacity: 0;
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  cursor: pointer;
}
