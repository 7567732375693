.applayout_container {
	height: 100vh;
	width: 100vw;
	display: flex;
	overflow: hidden;
}

.nav_container {
	height: 60px;
	font-size: 2rem;
	font-weight: 700;
	padding-left: 20px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
	padding-right: 3vw;
}

.nav_container button {
	border: none;
	background: none;
	font-size: 18px;
	font-weight: 500;
	cursor: pointer;
}

.sidebar_and_content_container {
	flex-grow: 1;
}

.sidebar_container {
	width: 70px;
	height: 100%;
	position: relative;
}

.sidebar_text {
	display: none;
	padding-left: 15px;
	transition: 0.5s;
}

.sidebar_text_show {
	display: inline-block;
}

.sidebar_main {
	position: relative;
	width: 70px;
	height: 100%;
	left: 0;
	background: rgb(214, 214, 214);
	display: flex;
	flex-direction: column;
	transition: 0.5s;
	overflow: hidden;
	z-index: 100;
}

.content_container {
	width: 100%;
	height: calc(100vh - 60px);
	padding: 20px;
}

.menu_icon_container {
	width: 70px;
	height: 60px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 8px;
	cursor: pointer;
	overflow: hidden;
}

.menu_line_one,
.menu_line_two,
.menu_line_three {
	height: 2.5px;
	width: 30px;
	background: black;
	border-radius: 50px;
	transition: 0.5s;
	transform-origin: center left;
}

.open_menu_icon .menu_line_one {
	transform: rotateZ(45deg);
}
.open_menu_icon .menu_line_two {
	transform: rotateY(-90deg);
}
.open_menu_icon .menu_line_three {
	transform: rotateZ(-45deg);
}

.menu_items_container {
	list-style: none;
	width: 100%;
	padding: 0 5px;
}

.menu_items_container li {
	width: 100%;
	height: 50px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	cursor: pointer;
	font-size: 1.2rem;
	border-radius: 10px;
	padding: 0 17px;
}

.menu_items_container li:hover {
	background: rgb(195, 195, 195);
}
